@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./variables.scss";

/*$color1: #000000;
$color2: #1a2543;
$color3: #475893;
$color4: #7e92ee;
$color5: #bed2ff;*/

body {
  background-color: $color3 !important;
}

body > div {
  height: 100%;
  position: absolute;
  width: 100%;
}

#content {
  overflow: auto;
}

#login-box, #register-box {
  width: 400px;
  background-color: $color5;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 60px;
  border-radius: 15px;
  color: $color4;

  input[type=text], input[type=password], input[type=email] {
    width: 100%;
  }

  .btn {
    margin-top: 7px;
    margin-right: 8px;
  }
}

.error-msg{
  color: #EE0000;
  font-size: 14px;
}

h2 {
  color: $color2;
}
